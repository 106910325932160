import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface Destination {
  id: string;
  name: string;
  cardImage: string;
  cardTitle: string;
  bengaliName: string;
  date: string;
  bannerYT: SafeResourceUrl;
  title: string;
  time: string;
  paras: paraContent[];
}

export interface paraContent {
  title?: string;
  pictures: string[];
  para: string;
  pictureFirst: boolean;
  bgGrey?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DestinationService {
  destinations: Destination[];

  bannabagram: Destination;
  dariyapur: Destination;
  tepantar: Destination;
  attractions: Destination;
  musicalCollaboration: Destination;
  connectingOnline: Destination;

  constructor(private sanatizer: DomSanitizer) {
    // Santiniketan
    this.bannabagram = {
      id: 'bannabagram',
      name: 'Bannabagram',
      cardImage: '../../../assets/images/dests/bannagram/card.jpg',
      cardTitle: 'a trail mix of culture',
      bengaliName: 'সবার রঙে রং মিলিয়ে ',
      date: 'December 3 - 5, 2021',
      bannerYT: sanatizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/vOMTKVa7iRA?autoplay=1&loop=1&playlist=vOMTKVa7iRA'
      ),
      title: 'a trail mix of culture',
      time: '11 am - 5 pm',
      paras: [
        {
          title: '',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/bannagram/ashram-1/1.jpg',
            '../../../assets/images/dests/bannagram/ashram-1/2.jpg',
            '../../../assets/images/dests/bannagram/ashram-1/3.jpg',
            '../../../assets/images/dests/bannagram/ashram-1/4.jpg',
            '../../../assets/images/dests/bannagram/ashram-1/5.jpg',
          ],
          para:
            '<p>A small village hemmed with wide green pastures is Bannabagram, a beautiful countryside ' +
            'destination. In it is a Baul Ashram; spread over 1.3 acres next to a forest range - primarily a ' +
            'jamming point for Bauls who reside in the vicinity. The second edition of Ripples Festival ' +
            'welcomes you here to experience Baul music and indulge in some interesting crafts of Bengal. ' +
            'Stay in eco-friendly cottages, interact with musicians and makers; all in all spend a winter ' +
            'weekend in serenity.</p>',
          bgGrey: true,
        },
        {
          title: 'Baul',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/bannagram/baul-2/1.jpg',
            '../../../assets/images/dests/bannagram/baul-2/2.jpg',
            '../../../assets/images/dests/bannagram/baul-2/3.jpg',
            '../../../assets/images/dests/bannagram/baul-2/4.jpg',
            '../../../assets/images/dests/bannagram/baul-2/5.jpg',
            '../../../assets/images/dests/bannagram/baul-2/6.jpg',
            '../../../assets/images/dests/bannagram/baul-2/7.jpg',
            '../../../assets/images/dests/bannagram/baul-2/8.jpg',
            '../../../assets/images/dests/bannagram/baul-2/9.jpg',
          ],
          para:
            '<p>The Bannabgram Baul Ashram is a place for the Bauls to preach and practise their art. The air here is attuned to their music. Ripples Festival brings together 15 Baul and Baulanis who will be performing every day from 3pm to 5pm. Come to the Ashram and be a witness to their soulful music.</p>',
        },
        {
          title: 'Kantha',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/bannagram/kantha-3/1.jpg',
            '../../../assets/images/dests/bannagram/kantha-3/2.jpg',
            '../../../assets/images/dests/bannagram/kantha-3/3.jpg',
            '../../../assets/images/dests/bannagram/kantha-3/4.jpg',
            '../../../assets/images/dests/bannagram/kantha-3/5.jpg',
            '../../../assets/images/dests/bannagram/kantha-3/6.jpg',
            '../../../assets/images/dests/bannagram/kantha-3/7.jpg',
          ],
          para:
            '<p>Kantha is an age-old quilting tradition where women used to stitch together layers of old cloth ' +
            'and embroider with simple run stitch. Today, Kantha is a craft led by women, some of whom are ' +
            'also found in the village of Ausgram in the Bardhaman district. Ripples Festival features these ' +
            'Kantha weavers in Bannabagram from 3rd to 5th December. Meet them in workshops and know ' +
            'about the unique stitch. Come over anytime between 11am to 4pm. ' +
            '</br></br>The festival also provides a virtual tour of the craft and its makers through a <a href="https://www.facebook.com/banglanatakdotcom/">Facebook Live</a> ' +
            'on 4th December from 11:30 am to 12:30 pm.</p>',
          bgGrey: true,
        },
        {
          title: 'Wooden Dolls',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/bannagram/wooden-dolls-4/1.jpg',
            '../../../assets/images/dests/bannagram/wooden-dolls-4/2.jpg',
            '../../../assets/images/dests/bannagram/wooden-dolls-4/3.jpg',
            '../../../assets/images/dests/bannagram/wooden-dolls-4/4.jpg',
            '../../../assets/images/dests/bannagram/wooden-dolls-4/5.jpg',
            '../../../assets/images/dests/bannagram/wooden-dolls-4/6.jpg',
            '../../../assets/images/dests/bannagram/wooden-dolls-4/7.jpg',
            '../../../assets/images/dests/bannagram/wooden-dolls-4/8.jpg',
          ],
          para:
            '<p>Wooden Dolls of Natungram hold an iconic status in Bengal’s repertoire of handicrafts. The ' +
            'dolls are characterized by traditional designs, bright colours and find place in every other ' +
            'Bengali household. At the festival, craftspersons from Natungram will be present with their ' +
            'handiwork. Visit the premises to interact with the makers and get your hands on some unique ' +
            'craft work. ' +
            '</br></br>The festival also provides a virtual tour of the craft and its makers through a <a href="https://www.facebook.com/banglanatakdotcom/">Facebook Live</a> on ' +
            '5th December from 11:30 am to 12:30 pm.</p>',
          bgGrey: false,
        },
        {
          title: 'Chadar Badar',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/bannagram/chadar-badar-5/1.jpg',
            '../../../assets/images/dests/bannagram/chadar-badar-5/2.jpg',
          ],
          para:
            '<p>Chadar Badar is an ancient indigenous form of puppetry; the puppets are made of bamboo or wood and are about 8 to 9 inches in height. Puppeteers tell the story by using many instruments such as Baje Banam, Lagara, Tirenya or flute and Tunda or Madal. The performances are not restricted to definite stories. At Ripples Festival, we bring you the opportunity to witness this age-old form of puppetry in Bannabagram.</p>',
          bgGrey: true,
        },
        {
          title: 'In and Around',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/bannagram/in-and-around-6/1.jpg',
            '../../../assets/images/dests/bannagram/in-and-around-6/2.jpg',
            '../../../assets/images/dests/bannagram/in-and-around-6/3.jpg',
            '../../../assets/images/dests/bannagram/in-and-around-6/4.jpg',
            '../../../assets/images/dests/bannagram/in-and-around-6/5.jpg',
            '../../../assets/images/dests/bannagram/in-and-around-6/6.jpg',
            '../../../assets/images/dests/bannagram/in-and-around-6/7.jpg',
            '../../../assets/images/dests/bannagram/in-and-around-6/8.jpg',
          ],
          para:
            '<p>Visit the Moukhira village and its surroundings of terracotta temples, zamindar’s palace, the Kalikapur Rajbari and other archaeological ruins like Pandurajar Dhibi situated within 10 kms from Bannabagram.</p>',
          bgGrey: false,
        },
      ],
    };

    // Joydev Kenduli
    this.dariyapur = {
      id: 'dariyapur',
      name: 'Dariyapur',
      cardImage: '../../../assets/images/dests/dariyapur/card.jpg',
      cardTitle: 'home to a primeval craft – Dokra ',
      bengaliName: 'আগুনে পুড়ে সোনা ফলে',
      date: 'December 3 - 5, 2021',
      bannerYT: sanatizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/nwVhwuytRhM?autoplay=1&loop=1&playlist=nwVhwuytRhM'
      ),
      title: 'home to a primeval craft – Dokra',
      time: '10 am - 7 pm',
      paras: [
        {
          title: 'Dokra',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/dariyapur/dokra-1/1.jpg',
            '../../../assets/images/dests/dariyapur/dokra-1/2.jpg',
            '../../../assets/images/dests/dariyapur/dokra-1/3.jpg',
            '../../../assets/images/dests/dariyapur/dokra-1/4.jpg',
          ],
          para:
            '<p>Dariyapur, a village in Bardhaman district, is popularly known for a unique craft, Dokra. One of the earliest methods of non-ferrous metal casting, Bengal Dokra has received the Geographical Indication (GI) tag. Artisans of the Dariyapur village settled here about 120 years back from which Dokra has evolved from a household craft to one which is revered all over the world for its simple yet rustic appeal.</p>',
          bgGrey: true,
        },
        {
          title: 'Craft Making',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/dariyapur/craft-making-2/1.jpg',
            '../../../assets/images/dests/dariyapur/craft-making-2/2.jpg',
            '../../../assets/images/dests/dariyapur/craft-making-2/3.jpg',
            '../../../assets/images/dests/dariyapur/craft-making-2/4.jpg',
          ],
          para:
            '<p>The making of Dokra is a long and laborious process. The complex method begins with modeling a clay core in the shape of the desired product and covering it with layers of wax and tar to later pour in the molten metal. After the moulded product is baked, the metal fills in and takes the shape of the wax. Excess clay is cleaned off and the final metal figure is polished and finished.</p>',
        },
        {
          title: 'Interact',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/dariyapur/interact-3/1.jpg',
            '../../../assets/images/dests/dariyapur/interact-3/2.jpg',
          ],
          para:
            '<p>Ripples Festival connects you with the Dokra artists residing in Dariyapur. Witness live ' +
            'demonstrations of Dokra craft making, know about intricate designs and folk motifs and meet the ' +
            'practitioners in engaging workshops. Artists make a wide range of items starting from utility to ' +
            'decor. Other than an elaborate display of the crafts and its making at the Dariyapur festival ' +
            'ground, you may also visit their folk art museum and witness the rare collection of antique Dokra ' +
            'artifacts. Visit Dariyapur between 3rd and 5th December, 2021 from 10 am to 7 pm. ' +
            'The festival also provides a virtual tour of the craft and its makers through a <a href="https://www.facebook.com/banglanatakdotcom/">Facebook Live</a> on ' +
            '3rd December from 11:30 am to 12:30 pm.</p>',
          bgGrey: true,
        },
        {
          title: 'In and Around',
          pictureFirst: true,
          pictures: ['../../../assets/images/dests/dariyapur/in-and-around-4/1.jpg', ],
          para:
            '<p>Drop by ancient temples like Sat Deul and 108 Shiva Temple situated within a distance of 20 kms from Dariyapur. Located at distance of another few kms are the Kankaleswari Kali Temple and Sarbamangala Temple.</p>',
          bgGrey: false,
        },
      ],
    };

    // Tepantar
    this.tepantar = {
      id: 'tepantar',
      name: 'Tepantar',
      cardImage: '../../../assets/images/dests/tepantar/card.jpg',
      cardTitle: 'A Festival in Theatre',
      bengaliName: 'প্রকৃতির রঙ্গমঞ্চ',
      date: 'December 3 - 5, 2021',
      bannerYT: sanatizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/ozoKSuMZ0RY?autoplay=1&loop=1&playlist=ozoKSuMZ0RY'
      ),
      title: 'a festival in theatre',
      time: '6 pm - 8:30 pm',
      paras: [
        {
          title: 'Abode in peace',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/tepantar/adobe-in-peace-1/1.jpg',
            '../../../assets/images/dests/tepantar/adobe-in-peace-1/2.jpg',
            '../../../assets/images/dests/tepantar/adobe-in-peace-1/3.jpg',
          ],
          para:
            '<p>Tepantar, located in the Paschim Bardhaman district is popular for being a theatre village. About 23 kms from Santiniketan, Tepantar is an abode of theatre practitioners situated amidst an extended green campus. Tepantar serves as a self-sustained campus where the group members practise farming, dairy and pisciculture. We welcome you here from December 3rd to 5th 2021 during which artists of Tepantar will be celebrating the folk culture of Bengal through six engaging plays. An interesting part of the festival is the presentation of dramas through a blend of Bengal’s varied folk art forms.</p>',
          bgGrey: true,
        },
        {
          title: 'Setu<br>3rd December, 6 pm',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/tepantar/setu-2/1.jpg',
            '../../../assets/images/dests/tepantar/setu-2/2.jpg',
            '../../../assets/images/dests/tepantar/setu-2/3.jpg',
          ],
          para:
            '<p>The three-day theatre festival will begin with the play ‘Setu’ , a production by Rishra Durayan. ' +
            'The play is an adaptation of Sunil Gangopadhyay’s story Setur Opore. The play directed by ' +
            'Deep Chakraborty shows the journey of a character from Bandipur to Muktipur (a place of ' +
            'confine to a place of liberation). Enjoy a journey in drama imbibed with Baul songs and Chau dance.</p>',
        },
        {
          title: 'Rupkathar Khon<br>3rd December, 7:15 pm',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/tepantar/rupkathar-3/1.jpg',
            '../../../assets/images/dests/tepantar/rupkathar-3/2.jpg',
            '../../../assets/images/dests/tepantar/rupkathar-3/3.jpg',
          ],
          para:
            '<p>The second production is an adaptation of Christian Andersen’s ‘Wild Swans’. Presented by Kaliaganj Ananya and Bichitra Natyasangstha, ‘Rupkathar Khon’ is a fairytale portrayed through Bengali folk form, Khon gaan and pala. The play directed by Bibhu Bushan Saha and Chandan Chakraborty narrates the story of a royal family where a princess frees her brothers from a spell.</p>',
          bgGrey: true,
        },
        {
          title: 'Megh Mallar<br>4th December, 6 pm',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/tepantar/megh-mallar-4/1.jpg',
            '../../../assets/images/dests/tepantar/megh-mallar-4/2.jpg',
            '../../../assets/images/dests/tepantar/megh-mallar-4/3.jpg',
          ],
          para:
            '<p>The second day begins with a famous adaptation of Bibhutibhushan Bandyopadhyay’s story, Megh Mallar. The plot revolves around one’s honest dedication towards music and emphasizes that one must truly love the art rather than merely praying to divine powers. Directed by Barun Kar, it will be presented by theatre group Gobardanga Mridangam.</p>',
        },
        {
          title: 'Nayantarar Geet<br>4th December, 7:15pm ',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/tepantar/nayantarar-5/1.jpg',
            '../../../assets/images/dests/tepantar/nayantarar-5/2.jpg',
            '../../../assets/images/dests/tepantar/nayantarar-5/3.jpg',
          ],
          para:
            '<p>Nayantarar Geet, the second show, is a Bengali version of William Shakespeare’s popular tragedy, Othello. The story narrates Othello\'s vain jealousy and blind rage on his wife that he eventually takes her life. Presented by Chakdaha Natyajan and written & directed by Sayak Siddique, the production will be presented through forms like Gambhira, Qawwali song and Chau dance.</p>',
          bgGrey: true,
        },
        {
          title: 'Gonsha re<br>5th December, 6pm',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/tepantar/ganshare-6/1.jpg',
            '../../../assets/images/dests/tepantar/ganshare-6/2.jpg',
            '../../../assets/images/dests/tepantar/ganshare-6/3.jpg',
          ],
          para:
            '<p>On the third day, Jalpaiguri Kalakushali will present Gonsha re, based on the story Ganesh written by Swapnomoy Chakraborty. Written and directed by Tamojit Roy, Gonsha re gives an essence of the traditional folk culture of North Bengal, highlighting the message of keeping children out of the contemporary rat race.</p>',
          bgGrey: false,
        },
        {
          title: 'Neel Piriter Ful<br>5th December, 7:15pm',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/tepantar/neel-7/1.jpg',
            '../../../assets/images/dests/tepantar/neel-7/2.jpg',
            '../../../assets/images/dests/tepantar/neel-7/3.jpg',
          ],
          para:
            '<p>The festival comes to an end on 5th December with its last theatre presentation, Neel Piriter Ful, an adaptation of A Midsummer Night’s Dream by William Shakespeare. The play is a love story based in Athens and the surrounding woods. The theatre is directed by Kallol Bhattacharyya.</p>',
          bgGrey: true,
        },
        {
          title: 'What to See',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/tepantar/what-to-see-8/1.jpg',
            '../../../assets/images/dests/tepantar/what-to-see-8/2.jpg',
            '../../../assets/images/dests/tepantar/what-to-see-8/3.jpg',
            '../../../assets/images/dests/tepantar/what-to-see-8/4.jpg',
          ],
          para:
            '<p>Dating back to the 17th century, Ichai Ghosh Deul is a tower temple about 6 kms from Tepantar.  An interesting place to stop by is the 180 years old brass chariot in Bankati village. Some distance away is Joydev Kenduli, the cradle of Baul music. Other places of attraction nearby are the Shyamrupa Kali Temple and Bhabani Pathak\'s Kali Temple. </p>',
          bgGrey: false,
        },
      ],
    };

    // Nanoor
    this.attractions = {
      id: 'attractions',
      name: 'Attractions',
      cardImage: '../../../assets/images/dests/attractions/card.jpg',
      cardTitle: 'Heritage sites in and around Bardhaman',
      bengaliName: 'সবুজের বুকে শিল্পের সাথে',
      date: null,
      bannerYT: sanatizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/ZfpHnV2gliQ?autoplay=1&loop=1&playlist=ZfpHnV2gliQ'
      ),
      title: 'heritage sites in and around Bardhaman',
      time: null,
      paras: [
        {
          title: 'Shrines of Bardhaman',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/attractions/shrines-1/1.jpg',
          ],
          para:
            '<p>Bardhaman has a cluster of temples in it, most of which are Kali shrines. The most famous among them is the Kankaleswari Kali Temple, the idol of which is a fierce and skeletal depiction of the goddess. Some other Kali temples are the Shyamrupa Kali Temple and Bhabani Pathak\'s Kali Temple. The Sarbamangala Temple built in the 1700s bearing the idol of Mata Sarbamangala is also a prominent temple. </p>',
          bgGrey: true,
        },
        {
          title: 'Moukhira',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/attractions/moukhira-2/1.jpg',
            '../../../assets/images/dests/attractions/moukhira-2/2.jpg',
            '../../../assets/images/dests/attractions/moukhira-2/3.jpg',
            '../../../assets/images/dests/attractions/moukhira-2/4.jpg',
          ],
          para:
            '<p>Moukhira is a village in the interiors of Bardhaman, marked with terracotta temples and elaborate palaces of Zamindar and the Kalikapur Rajbari, a heritage fortress built near about 400yrs back, an excellent architectural.</p>',
        },
        {
          title: '108 Shiva temples and Pital er Rath',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/attractions/shiva-3/1.jpg',
          ],
          para:
            '<p>Other interesting places to stop by are the 108 Shiva Temples, a group of 108 small temples constructed alongside each other and the Sat Deul temple built in the 7th century. The 180-year old brass chariot in Bankati village, situated 3 kms from Tepantar is also another attractive place to visit.</p>',
          bgGrey: true,
        },
        {
          title: 'Ichai Ghosh Deul',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/attractions/ichai-4/1.jpg',
          ],
          para:
            '<p>Located in Gourangapur, Ichai Ghosh Deul is also known as the Tower temple. An ASI listed monument, it is a unique temple structure of Bengal following the Rekh-Deul style of architecture.</p>',
          bgGrey: false,
        },
        {
          title: 'Joydev Kenduli',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/attractions/joydev-5/1.jpg',
          ],
          para:
            '<p>Small and serene in nature, Joydev Kenduli is a village known for the birth of poet Joydev. The cradle of Baul music, Joydev Kenduli hosts one of the biggest unorganised Baul festivals every year on the occasion of Makar Sankranti.</p>',
          bgGrey: true,
        },
      ],
    };

    this.musicalCollaboration = {
      id: 'musicalCollaboration',
      name: 'Musical Collaboration',
      cardImage: '../../../assets/images/dests/collabaration/card.jpg',
      cardTitle: 'From Bengal to Belfast',
      bengaliName: 'সাত সুরেতে নেইযে বাঁধন',
      date: 'December 3, 2021',
      bannerYT: sanatizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/oNc_ht7kNvI?autoplay=1&loop=1&playlist=oNc_ht7kNvI'
      ),
      title: 'from Bengal to Belfast',
      time: '3:30 pm - 4:30 pm',
      paras: [
        {
          title: 'Sound of Music',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/collabaration/music-1/1.jpg',
          ],
          para:
            '<p>Amidst music from the roots of Bardhaman, Ripples will also have an interesting musical collaboration to be presented digitally. The performance will witness a blend of Baul music by Girish Khyapa with traditional Irish music led by Jason O’Rourke. Accompanying them on the flute will be Mohan Tati and Deepmoy Das on percussion. This international co-creation will see musicians of Belfast and Bannabagram come together and put forward a musical presentation.</p>',
          bgGrey: true,
        },
        {
          title: 'Girish Khyapa',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/collabaration/girish-2/1.jpg',
          ],
          para:
            '<p>Girish Khyapa is an eminent Baul singer based in Bardhaman, West Bengal. Along with singing, he plays various traditional instruments such as Dotara and Ektara. He has performed on both national and international levels and sang for TV shows, movies and cultural exchange programs. Other than singing Baul songs, he also hosts a regular Akhra at the Joydev-Kenduli mela. Among international performances, he has performed at the International De Folklore De Noyon Festival in France, Transform-Trondheim World Music Festival in Norway, the Smithsonian Center for Folklife and Cultural Heritage in the United States and visited Washington D.C. as part of the initiative in 2018. There he attended the Smithsonian Folklife Festival 2018 and performed at the Library of Congress and Freer Sackler Gallery. Know more about <a href="https://baulfakiri.com/artist/girish-mondal/">Girish Khyapa</a>.</p>',
        },
        {
          title: 'Jason O\'Rourke',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/collabaration/jason-3/1.jpg',
            '../../../assets/images/dests/collabaration/jason-3/2.jpg',
            '../../../assets/images/dests/collabaration/jason-3/3.jpg',
          ],
          para:
            '<p>Jason O\'Rourke is a traditional Irish musician playing the concertina for over thirty years.</br>' +
            'In December 2014, he received the Artist Career Enhancement Scheme (ACES) award from the Arts Council NI to make his recent solo album, The Northern Concertina. Other than that he has made seven full-length albums. Jason has travelled to Siem Reap in Cambodia for a music exchange with local musicians Yaksao, and created collaborative projects with international musicians.  During the global pandemic he also managed a remote Belfast-Kolkata collaboration, the ‘Belonging’ project, with Bengali singer Deepmoy Das. Know more about <a href="http://jasonorourke.info/">Jason</a>.</p>',
          bgGrey: true,
        },
      ],
    };

    this.connectingOnline = {
      id: 'connectingOnline',
      name: 'Connecting Online',
      cardImage: '../../../assets/images/dests/connecting-online/card.jpg',
      cardTitle: 'Webinar and Live demonstration',
      bengaliName: 'সংযোগের বৈদ্যুতিক ধারা',
      date: 'December 3 - 5, 2021',
      bannerYT: sanatizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/UCs4frXP_-8?autoplay=1&loop=1&playlist=UCs4frXP_-8'
      ),
      title: 'webinar and live demonstration',
      time: '3:30 pm - 4:30 pm',
      paras: [
        {
          title: '3rd December, 2021',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/connecting-online/dec-3/0.jpg',
            '../../../assets/images/dests/connecting-online/dec-3/1.jpg',
            '../../../assets/images/dests/connecting-online/dec-3/2.jpg',
            '../../../assets/images/dests/connecting-online/dec-3/3.jpg',
            '../../../assets/images/dests/connecting-online/dec-3/4.jpg',
          ],
          para:
            '<p>Webinar: Meitheal - Working Together</p><br><p>The word Meitheal is an ancient Irish word that stands for putting in a collective effort, especially relevant post the pandemic when the world at large has connected online. Bridging gaps between Belfast and Bengal the webinar will present a digital musical collaboration and discussion.</p>' +
            '<p><br>Inaugural Speech - Barbara Wickham OBE, Director India, British Council</p><p><br>Discussants: Jason O\'Rourke, Girish Khyapa, Deepmoy Das</p><p></br>Moderator – Debalina Bhowmick\n</p>',
          bgGrey: false,
        },
        {
          title: '4th December, 2021',
          pictureFirst: false,
          pictures: [
            '../../../assets/images/dests/connecting-online/dec-4/1.jpg',
            '../../../assets/images/dests/connecting-online/dec-4/2.jpg',
            '../../../assets/images/dests/connecting-online/dec-4/3.jpg',
            '../../../assets/images/dests/connecting-online/dec-4/4.jpg',
            '../../../assets/images/dests/connecting-online/dec-4/5.jpg',
            '../../../assets/images/dests/connecting-online/dec-4/6.jpg',
            '../../../assets/images/dests/connecting-online/dec-4/7.jpg',
          ],
          para:
            '<p>Webinar: A Thought in Theatre</p><br><p>The global pandemic has extensively altered the course of life. Here comes an opportunity to be a part of the conversation between the theatre directors from UK and India to get their views on various aspects of theatre including their thoughts on innovation, application and presentation.</p><br><p>Discussants: Jonathan Kennedy, Abdul Shayek, Siddhartha Chakraborty, Shane Shambhu, Sayik Siddique, Gunakar Dev Goswami</p><p>Moderator – Amitava Bhattacharya</p>',
          bgGrey: true
        },
        {
          title: '5th December, 2021',
          pictureFirst: true,
          pictures: [
            '../../../assets/images/dests/connecting-online/dec-5/1.jpg',
            '../../../assets/images/dests/connecting-online/dec-5/2.jpg',
            '../../../assets/images/dests/connecting-online/dec-5/3.jpg',
            '../../../assets/images/dests/connecting-online/dec-5/4.jpg',
            '../../../assets/images/dests/connecting-online/dec-5/5.jpg',
            '../../../assets/images/dests/connecting-online/dec-5/6.jpg',
          ],
          para:
            '<p>Webinar: Rural Craft and Tourism Economy: Innovations for Resilience</p><br><p>Rural Craft Hubs of West Bengal are reaching out to  new markets through product innovations. Rural tourism is increasingly becoming popular and the villagers of artists attract tourists and heritage enthusiasts. Artists and experts will discuss how innovations in product and promotional strategies can build resilience.</p><br><p>Discussants: Dilip Sutradhar, Dr. Harriet Deacon, Dr. Jonathan Karkut, Suresh Karmakar, Dr. Debanjan Chakrabarti</p><p>Moderator – Ananya Bhattacharya</p>',
          bgGrey: false,
        }
      ],
    };

    this.destinations = [
      this.bannabagram,
      this.dariyapur,
      this.tepantar,
      this.attractions,
      this.musicalCollaboration,
      this.connectingOnline
    ];
  }

  scToTop() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);
  }
}
