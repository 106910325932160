import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'angular-bootstrap-md';
import {
  Destination,
  DestinationService,
} from 'src/app/services/destination.service';

@Component({
  selector: 'app-destination',
  templateUrl: './destination.component.html',
  styleUrls: ['./destination.component.scss'],
})
export class DestinationComponent implements OnInit {
  @ViewChild('imagesFrame') imagesFrame: ModalDirective;

  destination: Destination;
  index: number;
  allImages: string[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dsService: DestinationService
  ) {
    route.params.subscribe((data) => {
      this.destination = dsService.destinations.filter(
        (el) => el.id === data.id
      )[0];
      if (!this.destination) {
        router.navigate(['']);
      } else {
        this.index = dsService.destinations.indexOf(this.destination);
        this.allImages = this.destination
          ? [...[].concat(...this.destination?.paras.map((el) => el.pictures))]
          : [];
      }
    });
  }

  ngOnInit(): void {
    this.dsService.scToTop();
  }

  showImg(input: string) {
    const index = this.allImages.indexOf(input);
    const first = this.allImages.splice(index);
    const second = this.allImages;
    this.allImages = [];
    this.imagesFrame.show();
    setTimeout(() => {
      this.allImages = [...first, ...second];
    }, 100);
  }

  goHome() {
    this.router.navigate(['']);
  }

  goPrev() {
    let prev =
      this.index === 0
        ? this.dsService.destinations.length - 1
        : this.index - 1;
    this.router.navigate(['destination', this.dsService.destinations[prev].id]);
    this.dsService.scToTop();
  }

  goNext() {
    let next = (this.index + 1) % this.dsService.destinations.length;
    this.router.navigate(['destination', this.dsService.destinations[next].id]);
    this.dsService.scToTop();
  }
}
