<div class="container-fluid">
    <div class="row header">
        <div class="col-8">
            <img class="all-center ripples-logo" src="../../../assets/images/header/ripples-1.png" alt="">
        </div>
        <div class="col-2 bg-purple">
            <img class="all-center bnc-logo" src="../../../assets/images/header/banglanatak.png" alt="">
        </div>
        <div class="col-2 bg-purple">
            <img class="all-center bnc-logo" src="../../../assets/images/header/british-council-supported-by.png" alt="">
        </div>
    </div>
</div>