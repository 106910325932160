import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalDirective} from 'angular-bootstrap-md';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  validatingForm: FormGroup;
  @ViewChild('frame') frame: ModalDirective;
  @ViewChild('downloadFrame') downloadFrame: ModalDirective;
  @ViewChild('motFrame') motFrame: ModalDirective;
  @ViewChild('annFrame') annFrame: ModalDirective;
  @ViewChild('socFrame') socFrame: ModalDirective;
  @ViewChild('mapFrame') mapFrame: ModalDirective;

  files: { name: string; link: string }[];
  links: { name: string; link: string; soc: string }[];
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.validatingForm = new FormGroup({
      contactFormModalName: new FormControl('', Validators.required),
      contactFormModalEmail: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.email])
      ),
      contactFormModalSubject: new FormControl('', Validators.required),
      contactFormModalMessage: new FormControl('', Validators.required),
    });
    this.files = [
      {
        name: 'Festival Brochure',
        link: '../../../assets/downloads/ripples_2_brochure.pdf'
      }
    ];
    this.links = [
      { name: 'Share on Facebook', link: '', soc: 'fb' },
      { name: 'Share on Whatsapp', link: '', soc: 'whatsapp' },
      { name: 'Share on Twitter', link: '', soc: 'twitter' },
      { name: 'Share on Linkedin', link: '', soc: 'linkedin' },
    ];
  }

  download(input: { name: string; link: string }) {
    window.open(input.link);
  }

  get contactFormModalName() {
    return this.validatingForm.get('contactFormModalName');
  }

  get contactFormModalEmail() {
    return this.validatingForm.get('contactFormModalEmail');
  }

  get contactFormModalSubject() {
    return this.validatingForm.get('contactFormModalSubject');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  send() {
    if (this.validatingForm.status === 'VALID') {
      const body = {
        email: 'ripplesfestival@gmail.com',
        emailTitle: 'Enquiry from Ripples Festival website',
        emailBody:
          '<h3>' +
          this.validatingForm.value.contactFormModalName +
          ' just made an enquiry </h3>' +
          '<p>Name: ' +
          this.validatingForm.value.contactFormModalName +
          '</p><p>Email: ' +
          this.validatingForm.value.contactFormModalEmail +
          '</p><p> Subject: ' +
          this.validatingForm.value.contactFormModalSubject +
          '</p><p> Message: ' +
          this.validatingForm.value.contactFormModalMessage +
          '</p>',
      };
      this.sendMail(body).subscribe((data: any) => {
        // console.log('data', data, 'body', body);
      });
    }
  }

  sendMail(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(
      'https://mailer.bncmusical.co.in/sendmail',
      body,
      httpOptions
    );
  }

  public showForm(input: 'contact' | 'download' | 'announcement' | "social" | 'map') {
    if (input === 'contact') {
      this.frame.show();
    } else if (input === 'download') {
      this.downloadFrame.show();
    } else if (input === 'announcement') {
      this.annFrame.show();
    } else if (input === "social") {
      this.socFrame.show();
    } else if (input === "map") {
      this.mapFrame.show();
    }
  }

  socShare(input: "fb" | "whatsapp" | "twitter" | "linkedin"): void {
    if (input === "fb") {
      window.open('https://www.facebook.com/sharer.php?u=' + document.URL, 'NewWindow');
    } else if (input === "whatsapp") {
      window.open('whatsapp://send?text=' + document.URL);
    } else if (input === "twitter") {
      window.open('https://twitter.com/intent/tweet?url=' + document.URL, 'NewWindow');
    } else if (input === "linkedin") {
      window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + document.URL);
    }
  }

  openUrl(url: string): void {
    window.open(url, '_blank');
  }
}
