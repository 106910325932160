<div class="round-icon map-icon" (click)="showForm('map')" title="Directions">
    <mdb-icon far icon="map" size="2x"></mdb-icon>
</div>

<div class="round-icon message-icon" (click)="showForm('contact')" title="Enquiry">
    <mdb-icon far icon="envelope" size="2x"></mdb-icon>
</div>

<div class="round-icon download-icon" (click)="showForm('download')" title="Download">
    <mdb-icon fas icon="download" size="2x"></mdb-icon>
</div>

<div class="round-icon announcement-icon" (click)="showForm('social')" title="Share">
    <mdb-icon fas icon="share-alt" size="2x"></mdb-icon>
</div>

<div class="round-icon fb-icon" (click)="openUrl('https://fb.me/e/1b4WmlAJi')" title="Facebook">
    <mdb-icon fab icon="facebook-f" size="2x"></mdb-icon>
</div>

<div class="round-icon yt-icon" (click)="openUrl('https://www.youtube.com/user/banglanatak')" title="YouTube">
    <mdb-icon fab icon="youtube" size="2x"></mdb-icon>
</div>

<div mdbModal #frame="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto;">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body mx-3">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <br>


                <div class="form-container m-4">
                    <div class="text-center">
                        <h3>Write to us at <br> ripplesfestival@gmail.com</h3>
                        <p>or</p>
                        <h2>Send an Enquiry</h2>
                    </div>
                    <br>
                    <div class="md-form mb-5">
                        <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
                        <input type="text" id="form34" class="form-control" mdbInput mdbValidate
                            [formControl]="contactFormModalName">
                        <label for="form34">Your name</label>
                    </div>

                    <div class="md-form mb-5">
                        <!-- <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon> -->
                        <input type="email" id="form29" class="form-control" mdbInput mdbValidate
                            [formControl]="contactFormModalEmail">
                        <label for="form29">Your email</label>
                    </div>

                    <div class="md-form mb-5">
                        <!-- <mdb-icon fas icon="tag" class="prefix grey-text"></mdb-icon> -->
                        <input type="text" id="form32" class="form-control" mdbInput mdbValidate
                            [formControl]="contactFormModalSubject">
                        <label for="form32">Subject</label>
                    </div>

                    <div class="md-form">
                        <!-- <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon> -->
                        <textarea type="text" id="form8" class="md-textarea form-control" rows="1" mdbInput mdbValidate
                            [formControl]="contactFormModalMessage"></textarea>
                        <label for="form8">Your message</label>
                    </div>

                    <div class="text-center">
                        <button mdbBtn color="unique" class="waves-light" mdbWavesEffect
                            (click)='send(); (validatingForm.status=="VALID") && frame.hide() '
                            [disabled]="validatingForm.status=='INVALID'">send
                            <mdb-icon far icon="paper-plane" class="ml-1"></mdb-icon>
                        </button>
                    </div>

                </div>
                <br>
            </div>
        </div>
    </div>
</div>

<div mdbModal #downloadFrame="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto;">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body mx-3">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="downloadFrame.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <br>
                <div class="text-center">
                    <h2>Downloads</h2>
                    <br>
                    <div *ngFor="let d of files">
                        <button type="button" mdbBtn color="danger" mdbWavesEffect
                            (click)="download(d)">{{d.name}}</button>
                        <br>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
</div>

<div mdbModal #socFrame="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto;">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
            <div class="modal-body mx-3">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="socFrame.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <br>
                <div class="text-center">
                    <h2>Invite others to the Ripple Festival</h2>
                    <br>
                    <div *ngFor="let l of links">
                        <button type="button" mdbBtn color="danger" mdbWavesEffect
                            (click)='socShare(l.soc)'>{{l.name}}</button>
                        <br>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div>
</div>

<div mdbModal #motFrame="mdbModal" class="modal fade bottom modal-scrolling" id="frameModalTop" tabindex="-1"
    role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false}">
    <div class="modal-dialog modal-frame modal-bottom modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Body-->
            <div class="modal-body" style="padding: 10px;">

                <button type="button" class="close pull-right" aria-label="Close" (click)="motFrame.hide()"
                    style="top: 20px">
                    <span aria-hidden="true">×</span>
                </button>
                <div class="text-center">
                    <p>For Booking, download the list of Tour Operators <span><button type="button" mdbBtn
                                color="danger" class="relative waves-light" mdbWavesEffect
                                (click)="download(files[3])">Downlaod <mdb-icon fas icon="download" size="1.5x">
                                </mdb-icon></button></span></p>
                </div>

            </div>
            <!--/.Content-->
        </div>
    </div>
</div>


<div mdbModal #mapFrame="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto;">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body mx-3">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="mapFrame.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <br>
            </div>
            <div>
                <img class="d-block w-100" src="../../../assets/images/header/map.jpg" alt="First slide" >
            </div>
        </div>
    </div>
</div>

<!-- <div mdbModal #annFrame="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto;">
    <div class="modal-dialog modal-fluid" role="document">
        <div class="modal-content" style=" background: transparent; box-shadow: none;">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color: white;"
                    (click)="annFrame.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <br>
                <div class="w-100 text-center">
                    <img class="img-fluid" src="../../../assets/banner/iinBanner.jpg" style="max-height: 85vh;" alt="">
                </div>
                <br>
            </div>
        </div>
    </div>
</div> -->
