<div class="round-icon home-icon" (click)="goHome()">
    <mdb-icon fas icon="home" size="2x"></mdb-icon>
</div>

<mdb-icon class="left-arrow" fas icon="arrow-circle-left" size="2x" (click)="goPrev()"></mdb-icon>
<mdb-icon class="right-arrow" fas icon="arrow-circle-right" size="2x" (click)="goNext()"></mdb-icon>

<div *ngIf="destination?.bannerYT" class="container-fluid">
    <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" [src]="destination.bannerYT" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

        <!-- <div class="title-box p-4">
            <ul class="list-inline" style="margin-bottom: 0;">
                <li class="list-inline-item">
                    <h1 class="semibold-text">{{destination?.name}}</h1>
                </li>
                <li class="list-inline-item">
                    <h2 class="bengali-text">{{destination?.bengaliName}}</h2>
                </li>
            </ul>
            <h4>Date: {{destination?.date}}</h4>
        </div> -->
    </div>
</div>

<div class="title-box-small p-4">
    <ul class="list-inline" style="margin-bottom: 0;">
        <li class="list-inline-item">
            <h1 class="semibold-text">{{destination?.name}}</h1>
        </li>
        <li class="list-inline-item">
            <h2 class="bengali-text">{{destination?.bengaliName}}</h2>
        </li>
    </ul>
    <h4 *ngIf="destination?.date">Date: {{destination?.date}}</h4>
    <h4 *ngIf="destination?.time">Time: {{destination?.time}}</h4>
</div>

<br><br>

<div class="container text-center my-4">
    <h4 class="purple-text semibold-text">{{destination?.title}}</h4>
    <!-- <p class="regular-text">Time: {{destination?.time}}</p> -->
    <h4 *ngIf="destination?.id === 'connectingOnline'">Join us <a href="https://us02web.zoom.us/j/89409499166?pwd=NmxxL1U1dVh6OFgvTS9jdVFKSllzUT09" target="blank">On Zoom</a></h4>

</div>

<div class="d-none d-lg-block">

    <div class="container" *ngFor="let para of destination?.paras">
        <div class="row my-4">
            <div class="col p-0" *ngIf="para?.pictureFirst === false" [class.bg-grey]="para?.bgGrey">
                <div class="all-center" style="width: 80%;">
                    <h4 class="purple-text" [innerHTML]="para?.title"></h4>
                    <div [innerHTML]="para?.para"></div>
                </div>
            </div>
            <div class="col p-0">
                <div class="col p-0">
                    <mdb-carousel class="w-100 carousel slide carousel-fade" [animation]="'fade'">
                        <mdb-carousel-item *ngFor="let image of para?.pictures">
                            <div class="view w-100">
                                <img class="d-block w-100" [src]="image" alt="First slide" (click)="showImg(image)">
                                <div class="waves-light" mdbWavesEffect></div>
                            </div>
                        </mdb-carousel-item>
                    </mdb-carousel>
                </div>
            </div>
            <div class="col p-0" *ngIf="para?.pictureFirst === true">
                <div class="all-center" style="width: 80%;">
                    <h4 class="purple-text" [innerHTML]="para?.title"></h4>
                    <div [innerHTML]="para?.para"></div>
                </div>
            </div>
        </div>
        <br><br>
    </div>

</div>

<div class="d-block d-lg-none">

    <div class="container-fluid" *ngFor="let para of destination?.paras">
        <div class="row my-4">
            <div>
                <mdb-carousel class="w-100 carousel slide carousel-fade" [animation]="'fade'">
                    <mdb-carousel-item *ngFor="let image of para?.pictures">
                        <div class="view w-100">
                            <img class="d-block w-100" [src]="image" alt="First slide" (click)="showImg(image)">
                            <div class="waves-light" mdbWavesEffect></div>
                        </div>
                    </mdb-carousel-item>
                </mdb-carousel>
            </div>
            <div>
                <div class="p-4">
                    <h4 class="purple-text">{{para?.title}}</h4>
                    <div [innerHTML]="para?.para"></div>
                </div>
            </div>
        </div>
    </div>

</div>

<br><br>

<div mdbModal #imagesFrame="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto;">
    <div class="modal-dialog modal-fluid" role="document">
        <div class="modal-content" style=" background: transparent; box-shadow: none;" *ngIf="allImages?.length">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color: white;"
                    (click)="imagesFrame.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <br><br>
                <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'" [interval]="'0'">
                    <mdb-carousel-item *ngFor="let image of allImages">
                        <div class="view w-100">
                            <img class="mx-auto" [src]="image" alt="First slide" style="height: 80vh; max-width: 100%;">
                            <div class="waves-light" mdbWavesEffect></div>
                        </div>
                    </mdb-carousel-item>
                </mdb-carousel>
                <br>
            </div>
        </div>
    </div>
</div>
