<div class="container-fluid">
    <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/UCs4frXP_-8?autoplay=1&loop=1&playlist=UCs4frXP_-8" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </div>
</div>

<div class="container-fluid bg-grey">
    <div class="container intro-text">
        <!-- <img class="img-fluid" src="../../../assets/images/home/ripples-festival.png"
            alt=""> -->
        <!-- <h4 class="revelling semibold-text">R E V E L L I N G &nbsp; I N &nbsp; T H E &nbsp; R A R H</h4> -->
        <h1 class="semibold-text revelling">Blissful Bardhaman</h1>
        <h4>Ripples Festival Season 2 connects you to artists and craftspersons in Bardhaman. In the
            International Year of Creative Economy for Sustainable Development, banglanatak dot com in
            association with British Council India presents the second edition of Ripples Festival, promoting
            cultural heritage and rural cultural tourism strengthening grassroots creative enterprises. The
            festival aims to further the long-term sustainability of India’s craft economy and help local artists
            adapt to new ways of working with their audiences post the Covid-19 pandemic.</h4>
        <h4>Bardhaman, as a region, has a rich history to recount. Testament to a past of syncretism,
            Bardhaman has been a subject of both Hindu and Mughal emperors. Besides, it is also blessed
            with a lush natural setting, and a treasure of cultural wealth in the form of built heritage, folk
            forms, handicrafts and theatres.</h4>
        <h4>Ripples Festival brings to you a potpourri of Bardhaman’s culture through Baul music, Kantha,
            wooden dolls, Dokra, ChadarBadar and folk theatres. Be a part of this folk festival this winter!</h4>
    </div>
</div>

<div class="container-fluid destination-cards">
    <div class="row justify-content-center destinations">
        <div class="col-md-4 align-self-center" *ngFor="let ds of dsService.destinations">
            <div class="p-4">
                <img [src]="ds.cardImage" alt="" (click)="navigateTo(ds.id)">
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="bg-grey">
        <br><br><br><br><br>
    </div>
    <div><br><br><br><br><br></div>
</div>
